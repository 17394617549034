const validation = {
  email: {
    presence: {
      message: "^Please enter a valid email address.",
    },
    email: {
      message: "^Please enter a valid email address.",
    },
  },
  emailoptional: {
    email: {
      message: "^Please enter a valid email address.",
    },
  },
  fullname: {
    length: {
      minimum: 2,
      message: "^Please check your name.",
    },
    presence: {
      message: "^Please check your name.",
    },
  },
  message: {
    length: {
      minimum: 4,
      message: "^Please check your message.",
    },
    presence: {
      message: "^Please check your message.",
    },
  },
  firstname: {
    presence: {
      message: "^Please check your first name.",
    },
  },
  lastname: {
    presence: {
      message: "^Please check your last name.",
    },
  },
  caretakerfirstname: {
    presence: {
      message: "^Please check caregiver's first name.",
    },
    length: {
      minimum: 2,
      message: "^Please check caregiver's first name.",
    },
  },
  caretakerlastname: {
    presence: {
      message: "^Please check caregiver's last name.",
    },
    length: {
      minimum: 2,
      message: "^Please check caregiver's last name.",
    },
  },
  caretakergsm: {
    presence: {
      message: "^Please enter caregiver's phone number.",
    },
    length: {
      minimum: 6,
      message: "^Please enter caregiver's phone number.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Please enter caregiver's phone number.",
    },
  },
  caretakeremail: {
    email: {
      message: "^Please enter a valid caregiver's email address.",
    },
  },

  password: {
    presence: {
      message: "^Please enter a password.",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{1,200}$/,
      message:
        "^Password must contain a capital, lowercase, number and a special character.",
    },
    format: {
      pattern: /^((?!postop).)*$/i,
      message: "^Password cannot include \"postop\".",
    },
    length: {
      minimum: 12,
      message: "^Password should have minimum 12 characters.",
    },
  },
  passwordmixed: {
    presence: {
      message: "^Please enter a password.",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{1,200}$/,
      message:
        "^Password must contain a capital, lowercase, number and a special character.",
    },
  },
  passwordlogin: {
    presence: {
      message: "^Please enter a password.",
    },

    length: {
      minimum: 6,
      message: "^Password should have minimum 6 characters.",
    },
  },

  height: {
    presence: {
      message: "^Please enter your height",
    },
    length: {
      minimum: 1,
      message: "^Please enter your height",
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 400,
      flags: "i",
      message: "^Please check your height",
    },
  },
  weight: {
    presence: {
      message: "^Please enter your weight",
    },
    length: {
      minimum: 1,
      message: "^Please enter your weight",
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 400,
      flags: "i",
      message: "^Please check your height",
    },
  },
  linkyear: {
    presence: {
      message: "^Please enter birth year",
    },

    numericality: {
      greaterThan: 1900,
      lessThanOrEqualTo: 2050,
      flags: "i",
      message: "^Please enter birth year",
    },
  },
  nhsnumber: {
    presence: {
      message: "^Please enter your NHS number",
    },
    length: {
      minimum: 3,
      message: "^Please enter your NHS number",
    },
    numericality: {
      greaterThan: 0,
      flags: "i",
      message: "^Please check your NHS number",
    },
  },

  invitationcode: {
    length: {
      is: 4,
      message: "^Invitation code should have 4 digits.",
    },
  },
  gsm: {
    presence: {
      message: "^Please enter your phone number.",
    },
  },

  gsm2: {
    presence: {
      message: "^Please enter your phone.",
    },
    length: {
      minimum: 6,
      message: "^Please check your phone number.",
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "^Please check your phone number.",
    },
  },
};

export default validation;
