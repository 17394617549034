/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-misused-promises */
import type { FC } from "react";
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Easing,
  Animated,
  ActivityIndicator,
  Text,
  View,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import { TopInset } from "./TopInset";
import { Video, Audio } from "expo-av";
import { StatusBar } from "expo-status-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import RecordRTC from "recordrtc";

const getFileName = (fileExtension: string): string => {
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const date = d.getUTCDate();
  return (
    "RecordRTC-" +
    year +
    month +
    date +
    "-" +
    (Math.random() * 1000000).toFixed(0) +
    "." +
    fileExtension
  );
};

enum CameraType {
  front = "user",
  back = "environment",
}
type fn = (s?: any) => void;
const VideoRecorder: FC<{
  setIsCameraReady: fn;
  setHasPermission: fn;
  setStartRecording: fn;
  setStopRecording: fn;
  width: number;
  height: number;
  ratio: number;
  cameraType: CameraType;
}> = ({
  setIsCameraReady,
  setHasPermission,
  setStartRecording,
  setStopRecording,
  width,
  height,
  ratio,

  cameraType,
}) => {
  const [stream, setStream] = useState<MediaStream>();
  const streamRef = useRef<MediaStream>();
  const refVideo = useRef<HTMLVideoElement>();
  const [updateTrig, setUpdateTrig] = useState(0);
  const recorderRef = useRef<RecordRTC>();
  const setStreamAndRef = useCallback(
    (s) => {
      setStream(s);
      streamRef.current = s;
    },
    [setStream]
  );
  const startRecording = useCallback(() => {
    if (stream) {
      recorderRef.current = new RecordRTC(stream, {
        type: "video",
        mimeType: "video/webm",
      });
      recorderRef.current.startRecording();
      setUpdateTrig((a) => a + 1);
    } else {
      console.error("stream not found");
    }
  }, [stream]);
  const stopRecording = useCallback(
    (callback) => {
      recorderRef.current?.stopRecording(() => {
        const videoBlob = recorderRef.current?.getBlob();
        callback && callback(videoBlob);
      });
    },
    [updateTrig]
  );

  useEffect(() => {
    setStartRecording(() => startRecording);
    setStopRecording(() => stopRecording);
  }, [startRecording, stopRecording]);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: cameraType, aspectRatio: ratio },
        audio: false,
      })
      .then((cameraStream) => {
        setStreamAndRef(cameraStream);
        setIsCameraReady(true);
        setHasPermission(true);
      })
      .catch((error) => {
        if (error.name === "NotAllowedError") setHasPermission(false);
        else {
          alert(error.message);
        }
        console.error(error);
      });
    return () => {
      streamRef.current?.getTracks().forEach(function (track) {
        track.stop();
      });
      setStreamAndRef(undefined);
    };
  }, [cameraType]);
  useEffect(() => {
    if (refVideo.current && stream) {
      refVideo.current.srcObject = stream;
    }
  }, [stream, refVideo]);
  return <video ref={refVideo} autoPlay height={height} width={width} />;
};

export const ActivityVideo = (props: any): JSX.Element => {
  const [videoStatus, setVideoStatus] = React.useState<any>({});
  const [cameraType, setCameraType] = useState(CameraType.back);

  const [isLoading, setIsLoading] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean>();
  const [startRecording, setStartRecording] = useState<fn>();
  const [stopRecording, setStopRecording] = useState<fn>();
  const [ulmessage, setUlmessage] = useState("saving video...");
  const [uploadPercent, setUploadPercent] = useState(0); // default is 4:3
  const percentcomplete = useRef(new Animated.Value(0)).current;

  const videoplayer = React.useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [counter, setCounter] = useState(-4);
  const [videoBlob, setVideoBlob] = useState<Blob>();
  const opacityanim = useRef(new Animated.Value(1)).current;
  const videostartedRef = useRef(false);
  const counterIntervalRef = useRef<NodeJS.Timer>();
  const insets = useSafeAreaInsets();
  const video = videoBlob;
  const videoUri = useMemo(
    () => videoBlob && URL.createObjectURL(videoBlob),
    [videoBlob]
  );
  const ratio = 16 / 9; // Start with the system default
  function onPlaybackStatusUpdate(status: React.SetStateAction<{}>): void {
    setVideoStatus(status);
  }
  async function takeVideorecording(): Promise<void> {
    console.log("takeVideorecording");
    if (videostartedRef.current) return;
    videostartedRef.current = true;
    if (counterIntervalRef.current) clearInterval(counterIntervalRef.current);
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      playsInSilentModeIOS: true,
    });

    if (isCameraReady) {
      try {
        setIsRecording(true);
        setCounter(-4);
        counterIntervalRef.current = setInterval(() => {
          setCounter((prev) => {
            if (prev === -1) {
              startRecording!();
            } else if (prev === 10) {
              clearInterval(counterIntervalRef.current);
              stopRecording!(setVideoBlob);
              return prev;
            }
            return prev + 1;
          });
        }, 1000);
      } catch (e) {
        console.log(e);
        setTimeout(async () => {
          await takeVideorecording();
        }, 1300);
      }
    }
  }
  function switchCamera(): void {
    setCameraType(
      cameraType === CameraType.back ? CameraType.front : CameraType.back
    );
  }
  async function sendVideo(): Promise<any> {
    // generating a random file name
    if (!videoBlob) throw Error("videoBlob not found");
    const fileName = getFileName("webm");
    const fileObject = new File([videoBlob], fileName, {
      type: "video/webm",
    });
    // create FormData
    const formData = new FormData();
    formData.append("file", fileObject);
    formData.append("upload_preset", "n6glup7i");

    console.log("Uploading recorded-file to server.");

    const uploadUrl =
      "https://api.cloudinary.com/v1_1/postop/video/upload?upload_preset=n6glup7i";

    async function makeXMLHttpRequest(
      url: string,
      data: FormData
    ): Promise<any> {
      return await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.onreadystatechange = function () {
          if (request.readyState === 4) {
            if (request.status === 200) resolve(request);
            else reject(request);
          }
        };
        request.upload.onloadstart = function () {
          console.log("PHP upload started...");
        };
        request.upload.onprogress = function (event) {
          console.log(
            "PHP upload Progress " +
              Math.round((event.loaded / event.total) * 100) +
              "%"
          );
          Animated.timing(percentcomplete, {
            toValue: Math.round((event.loaded / event.total) * 100),
            easing: Easing.quad,

            duration: 500,
            useNativeDriver: true,
          }).start();
        };
        request.upload.onload = function () {
          console.log("progress-about-to-end");
        };
        request.upload.onload = function () {
          console.log("PHP upload ended. Getting file URL.");
        };
        request.upload.onerror = reject;
        request.upload.onabort = reject;
        request.open("POST", url);
        request.send(data);
      });
    }

    return await makeXMLHttpRequest(uploadUrl, formData).then((res) =>
      JSON.parse(res.responseText)
    );
  }

  async function retakePicture(): Promise<void> {
    console.log("retakePicture");
    videostartedRef.current = false;

    setIsRecording(false);
    setVideoBlob(undefined);
  }
  const screenwidth = Dimensions.get("window").width;
  const screenheight = Dimensions.get("window").height;
  const height =
    screenwidth > screenheight ? screenheight : screenwidth * ratio;
  const width = screenwidth > screenheight ? screenwidth / ratio : screenwidth;
  function fadeInIntro(): void {
    Animated.timing(opacityanim, {
      toValue: 0,
      duration: 1400,
      delay: 3000,
      useNativeDriver: true,
    }).start();
  }

  useEffect(() => {
    percentcomplete.addListener((value) => {
      console.log(value);
      setUploadPercent(Math.round(value.value));

      if (value.value > 80) setUlmessage("Almost finished.");
      if (value.value > 98) setUlmessage("Your upload is complete!");
    });

    fadeInIntro();

    return () => {
      percentcomplete.removeAllListeners();
    };
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return (
      <View style={{ flex: 1 }}>
        <Text style={{ marginTop: screenheight / 2, alignSelf: "center" }}>
          Please give camera access to continue.
        </Text>
      </View>
    );
  }

  return (
    <View style={[{ flex: 1 }, { backgroundColor: "#000000" }]}>
      {isLoading && (
        <View
          style={{
            flex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            zIndex: 1000,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator style={{ width: 200 }}></ActivityIndicator>
          <Text
            style={{
              fontFamily: "Questrial",
              fontSize: 64,
              color: "#1F2E99",
              width: "100%",
              top: screenwidth / 2 + 100,
              textAlign: "center",
              zIndex: 100,
              position: "absolute",
            }}
          >
            {uploadPercent}%
          </Text>
          <Text
            style={{
              fontFamily: "Questrial",
              fontSize: 20,
              color: "#0077FF",
              width: "100%",
              bottom: insets.bottom + 100,
              textAlign: "center",
              zIndex: 100,
              position: "absolute",
            }}
          >
            {ulmessage}
          </Text>
        </View>
      )}
      <TopInset />
      <StatusBar style="light" />

      {video && (
        <View
          style={{
            position: "absolute",
            top: 74 + insets.top + 12,
            width: 260,
            left: (screenwidth - 300) / 2,
            zIndex: 200,
          }}
        >
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 20,
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Does the video clearly show the wound?
          </Text>
        </View>
      )}

      {!isRecording && !video && (
        <View
          style={{
            position: "absolute",
            top: 74 + insets.top + 12,
            width: 350,
            left: (screenwidth - 270) / 2,
            zIndex: 200,
          }}
        >
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 20,
              color: "#ffffff",
              textAlign: "center",
              width: 270,
            }}
          >
            Take a 10 seconds video of your entire wound moving the camera
            around it.
          </Text>
        </View>
      )}

      <View
        style={{
          flex: 1,
          backgroundColor: "transparent",
          zIndex: -10,
          alignItems: "center",
        }}
      >
        {video && (
          <Video
            onPlaybackStatusUpdate={onPlaybackStatusUpdate}
            ref={videoplayer}
            width={width}
            height={height}
            style={{
              width: width - (90 + insets.bottom),
              height: height - (90 + insets.bottom),
              top: 0,
              position: "absolute",
              alignItems: "center",
            }}
            videoStyle={{
              margin: "auto",
            }}
            source={{
              uri: videoUri,
            }}
          />
        )}

        {video && (
          <TouchableOpacity
            style={{
              width: width - (90 + insets.bottom),
              height: height - (90 + insets.bottom),
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              zIndex: 2000,
            }}
            onPress={() =>
              videoStatus.isPlaying
                ? videoplayer.current.stopAsync()
                : videoplayer.current.playFromPositionAsync(0)
            }
          >
            {!videoStatus.isPlaying && (
              <Image
                source={require("../assets/images/cameravideoplaybutton.png")}
                style={{
                  width: 67,
                  height: 100,
                }}
              />
            )}
          </TouchableOpacity>
        )}

        <View
          style={[
            {
              position: "absolute",
              left: 0,
              top: 0,
              width: screenwidth,
              height: screenheight - 104 - insets.bottom,
              alignItems: "center",
            },
            isRecording && { bottom: 0 },
          ]}
        >
          {!video && (
            <VideoRecorder
              {...{
                setHasPermission,
                setIsCameraReady,
                setStartRecording,
                setStopRecording,
              }}
              height={height}
              width={width}
              ratio={ratio}
              cameraType={cameraType}
            />
          )}
        </View>

        <Animated.Text
          style={{
            opacity: opacityanim,
            position: "absolute",
            margin: "auto",
            zIndex: 100,
            height: 80,
            textAlignVertical: "center",
            top:
              insets.top +
              (screenheight - insets.top - insets.bottom - 103) / 2 -
              40,
            width: 250,
            textAlign: "center",
            alignItems: "center",
            fontFamily: "MontserratSemiBold",
            fontSize: 20,
            color: "#ffffff",
            lineHeight: 29,
          }}
        >
          Point the camera{"\n"}towards your wound
        </Animated.Text>

        {isRecording && (
          <>
            {!video && (
              <View
                style={[
                  {
                    width: screenwidth,
                    top: 0,
                    backgroundColor: "#1F2E89",
                    height: 131 + insets.top,
                    position: "absolute",
                  },
                ]}
              />
            )}
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              {counter > -1 && (
                <View
                  style={{
                    backgroundColor: "#FE7235",
                    width: 13,
                    height: 13,
                    borderRadius: 6.5,
                    marginTop: 61 + insets.top,
                  }}
                />
              )}

              {counter > -1 && (
                <Text
                  style={[styles.title1, { marginTop: 53 + 6 + insets.top }]}
                >
                  00:{counter < 10 && "0"}
                  {counter}
                </Text>
              )}

              {counter < -1 && (
                <Text style={[styles.title2, { marginTop: 17 + insets.top }]}>
                  {Math.abs(counter + 1)}
                </Text>
              )}
              {counter === -1 && (
                <Text style={[styles.title3, { marginTop: 53 + insets.top }]}>
                  Recording starts...
                </Text>
              )}
            </View>
          </>
        )}

        {!video && (
          <View
            style={{
              backgroundColor: "#FCF5EF",
              width: screenwidth,
              height: 104 + insets.bottom,
              flex: 1,
              alignItems: "center",
              position: "absolute",
              bottom: 0,
              zIndex: 200,
            }}
          >
            <TouchableOpacity
              disabled={!isCameraReady}
              onPress={() => {
                if (isRecording) {
                  stopRecording!(setVideoBlob);
                } else {
                  setIsRecording(true);
                  takeVideorecording();
                }
              }}
            >
              <Image
                source={require("../assets/images/camerashootbuttonnew.png")}
                style={{
                  width: 72,
                  height: 72,
                  marginTop: 13,
                  alignSelf: "center",
                }}
              />
            </TouchableOpacity>

            {!isRecording && (
              <TouchableOpacity
                style={{ position: "absolute", top: 32 - 13, right: "6%" }}
                onPress={() => {
                  switchCamera();
                }}
              >
                <Image
                  style={{
                    width: 108,
                    height: 53,
                    marginTop: 13,
                    alignSelf: "center",
                  }}
                  source={require("../assets/images/switchcamerabutton.png")}
                />
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>

      {video && (
        <View
          style={{
            position: "absolute",
            backgroundColor: "#FCF5EF",
            width: screenwidth,
            height: 106 + insets.bottom,
            bottom: 0,
            borderRadius: 5,
            zIndex: 10,
          }}
        />
      )}

      {video && (
        <>
          <TouchableOpacity
            style={{
              position: "absolute",
              bottom: 24 + insets.bottom,
              left: "6%",
              zIndex: 100,
            }}
            onPress={async () => {
              await retakePicture();
            }}
          >
            <View style={styles.buttonshadowretake}>
              <TopInset />
              <Image
                source={require("../assets/images/cameraretakebutton.png")}
                style={{ width: 156, height: 65 }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              position: "absolute",
              bottom: 24 + insets.bottom,
              right: "6%",
              zIndex: 100,
            }}
            onPress={() => {
              setIsLoading(true);

              sendVideo()
                .then((v) => props.nextStep(v))
                .catch((err) => {
                  alert(err);
                  console.error(err);
                  setIsLoading(false);
                });
            }}
          >
            <View style={styles.buttonshadowexit}>
              <Image
                source={require("../assets/images/camerayesbutton.png")}
                style={{ width: 156, height: 65 }}
              />
            </View>
          </TouchableOpacity>
        </>
      )}

      {!isRecording && (
        <View
          style={[
            {
              width: screenwidth,
              top: 0,
              height: 193 + insets.top,
              position: "absolute",
              backgroundColor: "#000000",
              opacity: 0.35,
            },
            isRecording && { height: 131 + insets.top },
          ]}
        />
      )}

      {video && (
        <View
          style={{
            backgroundColor: "#000000",
            opacity: 0.35,
            width: screenwidth,
            height: 193 + insets.top,
            zIndex: 10,
            top: 0,
            position: "absolute",
          }}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  buttonshadowexit: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,

    backgroundColor: "transparent",
  },
  buttonshadowretake: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
    backgroundColor: "transparent",
  },

  cameraretakebutton: {
    width: 117,
    height: 37,
  },

  title1: {
    alignSelf: "center",

    marginLeft: 8,
    fontFamily: "MontserratSemiBold",
    fontSize: 24,
    color: "#ffffff",
  },
  title2: {
    alignSelf: "center",

    lineHeight: 103,
    textAlignVertical: "center",
    fontFamily: "Questrial",
    fontSize: 100,
    color: "#ffffff",
  },
  title3: {
    alignSelf: "center",
    marginTop: 53 + 20,
    fontFamily: "MontserratSemiBold",
    fontSize: 24,
    color: "#ffffff",
  },
});
