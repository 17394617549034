import * as React from "react";
import { View } from "react-native";

import { SafeAreaInsetsContext } from "react-native-safe-area-context";

export const TopInset = (): JSX.Element => (
  <SafeAreaInsetsContext.Consumer>
    {(insets) => (
      <View
        style={{
          paddingTop: insets?.top ?? 0 - 20,
          backgroundColor: "transparent",
        }}
      />
    )}
  </SafeAreaInsetsContext.Consumer>
);
