import * as React from "react";
import { StyleSheet, Text, View, Image, Alert } from "react-native";
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import validate from "../validate_wrapper";
import { OrangeButton } from "./OrangeButton";
import * as Sentry from "@sentry/browser";

export const VerifyBirthyear = (props: any): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(false);

  const CELL_COUNT = 4;
  const [value, setValue] = React.useState("");
  const [linkyear, setLinkyear] = React.useState("");

  useClearByFocusCell({
    value,
    setValue,
  });

  function validateForm(): void {
    setIsLoading(true);
    const linkyearError = validate("linkyear", linkyear);

    if (!linkyearError) {
      checkLinkYear();
    } else {
      alert("\n" + (linkyearError ? `\n${linkyearError}\n` : "") + "\n");
      setIsLoading(false);
    }
  }

  async function checkLinkYear(): void {
    try {
      const res = await fetch(
        `${global.apiurl}/linkform/checkpatientbirthyear`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            linkcode: props.linkcode,
            linkyear,
          }),
        }
      );
      setIsLoading(false);
      if (res.status === 422) {
        Alert.alert("Link can not be verified", "Please check the form");
        throw new Error("Validation failed.");
      }
      const resData = await res.json();
      if (resData.result) {
        props.setLinkyear(linkyear);

        global.patientactivityextra = resData.patientactivityextra;
        global.patientactivityextra_qa =
          global.patientactivityextra.eq5d5l == 1 ||
          global.patientactivityextra.phq9 == 1 ||
          global.patientactivityextra.prems == 1 ||
          global.patientactivityextra.ssis == 1 ||
          global.patientactivityextra.ohsoks == 1 ||
          global.patientactivityextra.wqol == 1 ||
          global.patientactivityextra.fhs == 1;

        props.nextStep();
      } else {
        alert("Please check the form.");
      }
    } catch (err) {
      setIsLoading(false);
      alert("There is a problem with connection please try again later.");
      Sentry.captureException(err);
    }
  }

  return (
    <View style={{ maxWidth: 500, alignSelf: "center" }}>
      <Image
        source={require("../assets/images/logopostop1.1.png")}
        style={styles.logo}
      />

      <Text style={styles.title1}>Let’s confirm your{"\n"}identity</Text>

      <Text style={styles.title2}>
        Please insert the Patient’s year of birth to confirm your identity.
      </Text>

      <Text style={styles.title3}>Year of birth</Text>
      <View style={styles.shadowinputnobg}>
        <CodeField
          {...props}
          value={linkyear}
          onChangeText={setLinkyear}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          renderCell={({ index, symbol, isFocused }) => (
            <Text
              key={index}
              style={[styles.cell, isFocused && styles.focusCell]}
            >
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          )}
        />
      </View>
      <OrangeButton
        disabled={isLoading}
        onPress={() => {
          validateForm();
        }}
        title="Verify invitation code"
        width={"84%"}
        style={{ marginTop: 50 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  navbutton: {
    zIndex: 3,
    position: "absolute",
    top: 45,
    right: "8%",
  },
  navbuttontext: {
    fontSize: 18,
    fontFamily: "Montserrat",
    color: "#1F2E99",
  },

  title: { textAlign: "center", fontSize: 30 },
  codeFieldRoot: { marginTop: 15, height: 56 },
  cell: {
    width: 56,
    height: 56,
    lineHeight: 54,
    fontSize: 24,
    color: "#1F2E99",
    fontFamily: "Questrial",
    borderWidth: 1,
    borderColor: "#bbdbff",
    borderRadius: 15,
    shadowColor: "#1A3C44",
    textAlign: "center",
    paddingHorizontal: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.03,
    shadowRadius: 3,

    elevation: 0,
  },
  focusCell: {
    borderColor: "#000",
  },
  logo: {
    width: 210.5,
    height: 69,
    marginLeft: "8%",
    marginTop: 65,
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  phoneinput: {
    flex: 1,
    color: "#1F2E99",
    fontFamily: "Montserrat",
    fontSize: 20,
    marginRight: 10,
    marginLeft: 5,
  },

  shadowinput: {
    height: 56,
    alignItems: "center",
    marginTop: 15,
    width: "84%",
    marginHorizontal: "8%",
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#818181",
    backgroundColor: "#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,

    elevation: 5,
  },
  shadowinputnobg: {
    marginHorizontal: "8%",
  },

  container: {
    flex: 1,
    alignContent: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#ffffff",
  },
  title1: {
    width: "83%",
    fontSize: 24,
    fontFamily: "MontserratSemiBold",
    color: "#1F2E99",
    marginTop: 30,
    marginLeft: "8%",
  },
  title2: {
    width: "83%",
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
    marginTop: 23,
    marginLeft: "8%",
  },
  title3: {
    width: "83%",
    fontSize: 20,
    fontFamily: "Questrial",
    color: "#798599",
    marginTop: 34,
    marginLeft: "8%",
  },
  title3a: {
    fontSize: 15,
    fontFamily: "Questrial",
    color: "#4F4F4F",
    marginLeft: "8%",
  },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    textDecorationLine: "underline",
    color: "#0077FF",

    position: "relative",
  },
  title5: {
    width: "83%",
    fontSize: 15,
    fontFamily: "Lato",
    color: "#0077FF",
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
