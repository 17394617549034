/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Easing,
  Platform,
  Animated,
  ActivityIndicator,
  Text,
  View,
  StyleSheet,
  Alert,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import { Camera, Constants } from "expo-camera";
import { OrangeButton } from "./OrangeButton";
import { TopInset } from "./TopInset";
import * as ImageManipulator from "expo-image-manipulator";
import { Video, Audio } from "expo-av";
import * as FileSystem from "expo-file-system";
import * as ImagePicker from "expo-image-picker";
import { StatusBar } from "expo-status-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const ActivityCamera = (props: any): JSX.Element => {
  const [videoStatus, setVideoStatus] = React.useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState(false);
  const [uploadcompleted, setUploadcompleted] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [isRatioSet, setIsRatioSet] = useState(false);
  const [ratio, setRatio] = useState(); // default is 4:3
  const [ulmessage, setUlmessage] = useState(
    "Sending image and video\nto your Doctor..."
  );
  const [uploadPercent, setUploadPercent] = useState(0); // default is 4:3
  const percentcomplete = useRef(new Animated.Value(0)).current;
  const [setCameraPadding] = useState(0); // default is 4:3
  const { height: screenWidth } = Dimensions.get("window");
  const screenRatio = 16 / 9; // screenHeight / screenWidth;
  const [type, setType] = useState(Camera.Constants.Type.back);

  const initarray = [
    { text: "entire wound", photo: null },
    { text: "top of your wound", photo: null },
    { text: "bottom of your wound", photo: null },
  ];
  const videoplayer = React.useRef(null);
  const [completed, setCompleted] = useState(false);
  const [recording2, setRecording2] = useState(false);
  const [woundsarray] = useState(initarray);
  const [photo, setPhoto] = useState(null);
  const [counter, setCounter] = useState(-4);
  const [video, setVideo] = useState(null);
  const [photono, setPhotono] = useState(0);
  const opacityanim = useRef(new Animated.Value(1)).current;
  const insets = useSafeAreaInsets();
  const [camera, setCamera] = useState(null);
  const [setTask] = useState(null);
  const [setTask2] = useState(null);

  type RatioOptions = Record<string, number>;

  global.photoshot = "";

  function onPlaybackStatusUpdate(status: React.SetStateAction<{}>): void {
    setVideoStatus(status);
  }

  async function takeVideorecording(): Promise<void> {
    console.log("takeVideorecording");
    if (global.videostarted) return;
    global.videostarted = true;

    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      playsInSilentModeIOS: true,
    });

    if (isCameraReady) {
      if (camera) {
        try {
          setRecording2(true);
          setCounter(-4);
          setTimeout(() => {
            setCounter(-3);
          }, 750);
          setTimeout(() => {
            setCounter(-2);
          }, 1500);
          setTimeout(() => {
            setCounter(-1);
          }, 2250);
          setTimeout(async () => {
            setCounter(0);
            const videoRecordPromise = await camera.recordAsync({
              quality: Constants.VideoQuality["720p"],
              maxDuration: 15,
              mute: true,
            });

            if (videoRecordPromise) {
              const data = await videoRecordPromise;
              const source = data.uri;
              if (source) {
                setVideo(source);
                setRecording2(false);
              }
            }
          }, 3000);
          setTimeout(() => {
            setCounter(1);
          }, 4000);
          setTimeout(() => {
            setCounter(2);
          }, 5000);
          setTimeout(() => {
            setCounter(3);
          }, 6000);
          setTimeout(() => {
            setCounter(4);
          }, 7000);
          setTimeout(() => {
            setCounter(5);
          }, 8000);
          setTimeout(() => {
            setCounter(6);
          }, 9000);
          setTimeout(() => {
            setCounter(7);
          }, 10000);
          setTimeout(() => {
            setCounter(8);
          }, 11000);
          setTimeout(() => {
            setCounter(9);
          }, 12000);
          setTimeout(() => {
            setCounter(10);
          }, 13000);

          setTimeout(() => camera.stopRecording(), 13100);
          if (videoRecordPromise) {
            const data = await videoRecordPromise;
            const source = data.uri;
            if (source) {
              setVideo(source);
              setRecording2(false);
            }
          }
        } catch (e) {
          console.log(e);
          setTimeout(() => {
            takeVideorecording();
          }, 1300);
        }
      }
    }
  }

  const prepareRatio = useCallback(async () => {
    let desiredRatio: string | null = "16:9"; // Start with the system default
    let winnerRatio = "";
    if (Platform.OS === "android") {
      const ratios = await camera?.getSupportedRatiosAsync();
      if (ratios) {
        const realRatios: RatioOptions = {};
        let minDistance = null;
        let winnerRatioFloat = null;
        // eslint-disable-next-line no-restricted-syntax
        for (const tempRatio of ratios) {
          console.log("ratio:" + tempRatio);
          const parts = tempRatio.split(":");
          const realRatio = parseInt(parts[0], 10) / parseInt(parts[1], 10);

          if (Math.floor(10 * realRatio) != Math.floor(screenRatio * 10)) {
            // if (tempRatio=="11:9") console.log("11:9 is here");
            realRatios[tempRatio] = realRatio;
            const distance = Math.abs(screenRatio - realRatio);
            console.log("xratio:" + distance);

            // distances[tempRatio] = realRatio;
            if (minDistance == null) {
              winnerRatio = tempRatio;
              winnerRatioFloat = realRatio;
              minDistance = distance;
              console.log("winnerRatio:" + winnerRatio);
            } else if (distance < minDistance) {
              winnerRatio = tempRatio;
              winnerRatioFloat = realRatio;

              console.log("winnerRatio:" + winnerRatio);

              minDistance = distance;
            }
          } else {
            winnerRatio = tempRatio;
            winnerRatioFloat = realRatio;

            console.log("winnerRatio:" + winnerRatio);

            minDistance = 0;
          }
        }
        desiredRatio = minDistance;

        if (winnerRatio) {
          const remainder = Math.floor(
            (winnerRatioFloat * screenWidth - screenWidth) / 2
          );
          console.log("remainder:" + remainder);
          console.log("winnerRatio:" + winnerRatio);

          setCameraPadding(remainder);
          setRatio(winnerRatio);
          console.log("final ratio:" + desiredRatio);

          setIsRatioSet(true);
        }
      }
    } else {
      setIsRatioSet(true);
      setRatio(desiredRatio);
    }
  }, [camera, ratio, screenRatio, screenWidth]);

  async function takePicture(): Promise<void> {
    if (camera) {
      // https://github.com/expo/expo/issues/706#issuecomment-397824679
      woundsarray[photono].photo = await camera.takePictureAsync({
        skipProcessing: true,
        mute: true,
        orientation: "portrait",
        base64: true,
      });
      setPhoto(woundsarray[photono].photo);
      global.photo = woundsarray[photono].photo;
    }
  }

  function switchCamera(): void {
    setType(
      type === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  }

  async function pickFromGallery(): Promise<void> {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [9, 16],
      quality: 1,
      base64: true,
    });
    if (!result.cancelled) {
      const asset = result.assets[0];
      if (asset.uri.includes("base64")) {
        asset.base64 = asset.uri;
      }
      woundsarray[photono].photo = asset;
      setPhoto(woundsarray[photono].photo);
      global.photo = woundsarray[photono].photo;
    }
  }

  async function sendVideo(): Promise<void> {
    if (isLoading) return;
    setIsLoading(true);
    const API_URL = "https://api.cloudinary.com/v1_1/postop/video/upload";
    const uploadHeaders = {
      "content-type": "multipart/form-data",
    };
    const mimeType = "video/mp4";
    const tasklocal = FileSystem.createUploadTask(
      API_URL,
      video,
      {
        uploadType: FileSystem.FileSystemUploadType.MULTIPART,
        fieldName: "file",
        mimeType,
        parameters: {
          upload_preset: "n6glup7i",
        },
      },
      (data) => {
        Animated.timing(percentcomplete, {
          toValue: Math.round(
            (data.totalByteSent / data.totalBytesExpectedToSend) * 90
          ),
          easing: Easing.quad,

          duration: 500,
          useNativeDriver: true,
        }).start();
      }
    );

    setTask(tasklocal);

    tasklocal
      .uploadAsync()
      .then((response) => JSON.parse(response.body))
      .then((responseJson) => {
        sendAll(responseJson).catch((e) => {
          throw e;
        });
      })
      .catch((err) => {
        setIsLoading(false);
        Sentry.handleException(err);
      });
  }

  async function sendAll(videoresponse: {
    asset_id: any;
    public_id: any;
    secure_url: any;
  }): Promise<void> {
    console.log("sendall");
    setIsLoading(true);
    let tokenparam = "None";
    if (userStore.sessiontoken) {
      tokenparam = `Bearer ${userStore.sessiontoken}`;
    }

    const resizeimg1 = await ImageManipulator.manipulateAsync(
      woundsarray[0].photo.uri,
      [{ resize: { width: 1920 } }],
      { compress: 0.65, format: ImageManipulator.SaveFormat.JPEG }
    );
    // ,{rotate:90},{crop:{height:1920,originX:0,originY:0,width:1920}},{rotate:-90}

    const screenwidth = Dimensions.get("window").width;
    const screenheight = Dimensions.get("window").height;

    const imagebottom =
      (screenheight - (104 + insets.bottom) - (324 / 375) * screenwidth) / 2 -
      insets.top;

    const imagescale = Math.round(1920 / screenwidth);
    const tasklocal2 = FileSystem.createUploadTask(
      `${global.apiurl}/patients/savemedia`,
      resizeimg1.uri,
      {
        uploadType: FileSystem.FileSystemUploadType.MULTIPART,
        fieldName: "image1",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: tokenparam,
        },
        mimeType: "image/jpg",
        parameters: {
          video_asset_id: videoresponse.asset_id,
          video_public_id: videoresponse.public_id,
          video_url: videoresponse.secure_url,
          imagebottom,
          imagescale,
        },
      },
      (data) => {
        // setUploadPercent(Math.round(data.totalByteSent/data.totalBytesExpectedToSend*95));

        Animated.timing(percentcomplete, {
          toValue: Math.round(
            (data.totalByteSent / data.totalBytesExpectedToSend) * 10 + 90
          ),
          easing: Easing.quad,

          duration: 500,
          useNativeDriver: true,
        }).start();

        console.log({ data });
      }
    );

    setTask2(tasklocal2);

    tasklocal2
      .uploadAsync()
      .then((response) => JSON.parse(response.body))
      .then((responseJson) => {
        console.log(responseJson);

        Animated.timing(percentcomplete, {
          toValue: 100,
          easing: Easing.linear,

          duration: 300,
          useNativeDriver: true,
        }).start();

        setTimeout(() => {
          setIsLoadingFinished(true);
          setUploadcompleted(true);
          setIsLoading(false);
        }, 350);
        setTimeout(() => {
          navigation.replace("ActivityCameraDoneScreen");
        }, 2000);

        // sendAll(responseJson)
      })
      .catch((err) => {
        setIsLoading(false);

        Alert.alert("Unable to upload", "Please try again");

        console.log(err);
        // _uploadImage();
      });
  }

  function nextPhoto(): void {
    if (photono < 2) {
      setPhotono(photono + 1);
      setPhoto(null);
    } else {
      setPhotono(photono + 1);
    }
  }

  const onCameraReady = async (): Promise<void> => {
    setIsCameraReady(true);
    console.log("camera ready");

    if (!isRatioSet) {
      await prepareRatio();
    }
  };

  async function retakePicture(): Promise<void> {
    console.log("retakePicture");
    global.videostarted = false;

    if (completed) {
      setPhotono(0);
      setCompleted(false);
    }
    setPhoto(null);
    setVideo(null);
  }
  const [hasPermission, setHasPermission] = useState(null);
  const screenwidth = Dimensions.get("window").width;
  const screenheight = Dimensions.get("window").height;
  function fadeInIntro(): void {
    Animated.timing(opacityanim, {
      toValue: 0,
      duration: 1400,
      delay: 3000,
      useNativeDriver: true,
    }).start();
  }

  useEffect(() => {
    percentcomplete.addListener((value) => {
      console.log(value);
      setUploadPercent(Math.round(value.value));

      if (value.value > 80) setUlmessage("Almost finished.");
      if (value.value > 98) setUlmessage("Your upload is complete!");
    });

    setTimeout(() => {
      setType(Camera.Constants.Type.back);
    }, 1000);

    fadeInIntro();

    return () => {
      percentcomplete.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    global.videostarted = false;

    void (async () => {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();

      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      setHasPermission(status === "granted");
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return (
      <View style={{ flex: 1 }}>
        <Text style={{ marginTop: screenheight / 2, alignSelf: "center" }}>
          Please give camera access to continue.
        </Text>
      </View>
    );
  }

  return (
    <View
      style={[
        { flex: 1 },
        { backgroundColor: "#000000" },
        completed && { backgroundColor: "#ffffff" },
      ]}
    >
      <TopInset />
      <StatusBar style="light" />

      {isLoading && false && (
        <View
          style={{
            flex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.7,
            backgroundColor: "#555555",
            width: "100%",
            height: "100%",
            zIndex: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator style={{ width: 200 }}></ActivityIndicator>
        </View>
      )}

      {photo && (
        <View
          style={{
            position: "absolute",
            top: 74 + insets.top + 12,
            width: 260,
            left: (screenwidth - 300) / 2,
            zIndex: 200,
          }}
        >
          <Text
            style={{ fontFamily: "LatoBold", fontSize: 20, color: "#ffffff" }}
          >
            Does the photo clearly show the wound?
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          ></View>
        </View>
      )}

      {video && !completed && (
        <View
          style={{
            position: "absolute",
            top: 74 + insets.top + 12,
            width: 260,
            left: (screenwidth - 300) / 2,
            zIndex: 200,
          }}
        >
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 20,
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Does the video clearly show the wound?
          </Text>
        </View>
      )}

      {photono > 0 && !recording2 && !video && (
        <View
          style={{
            position: "absolute",
            top: 74 + insets.top + 12,
            width: 350,
            left: (screenwidth - 270) / 2,
            zIndex: 200,
          }}
        >
          <Text
            style={{
              fontFamily: "LatoBold",
              fontSize: 20,
              color: "#ffffff",
              textAlign: "center",
              width: 270,
            }}
          >
            Take a 10 seconds video of your entire wound moving the camera
            around it.
          </Text>
        </View>
      )}

      {!completed && (
        <View style={{ flex: 1, backgroundColor: "transparent", zIndex: -10 }}>
          {photo && (
            <Image
              resizeMethod="resize"
              source={photo}
              style={{
                width: screenwidth,
                height: screenheight - 106,
                position: "absolute",
                bottom: 106,
              }}
            />
          )}

          {video && (
            <Video
              onPlaybackStatusUpdate={onPlaybackStatusUpdate}
              ref={videoplayer}
              style={{
                width: screenwidth,
                height: (screenwidth * 16) / 9,
                bottom: 106 + insets.bottom,
                position: "absolute",
              }}
              source={{
                uri: video,
              }}
              resizeMode="contain"
            />
          )}

          {video && (
            <TouchableOpacity
              style={{
                width: screenwidth,
                height: screenheight - 106 - 131 - insets.top,
                marginTop: 131 + insets.top,
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                zIndex: 2000,
              }}
              title={videoStatus.isPlaying ? "Pause" : "Play"}
              onPress={() =>
                videoStatus.isPlaying
                  ? videoplayer.current.stopAsync()
                  : videoplayer.current.playFromPositionAsync(0)
              }
            ></TouchableOpacity>
          )}

          {false && <Text>{ratio}</Text>}

          <View
            style={[
              {
                position: "absolute",
                left: 0,
                bottom: 104 + insets.bottom,
                width: screenwidth,
                height: (screenwidth * 16) / 9,
              },
              recording2 && { bottom: 0 },
            ]}
          >
            {!photo && !video && (
              <Camera
                style={[
                  {
                    width: screenwidth,
                    height: (screenwidth * 16) / 9,
                    bottom: 0,
                  },
                  recording2 && { height: (screenwidth * 16) / 9 },
                ]}
                ratio={ratio}
                type={type}
                autoFocus={Constants.AutoFocus.on}
                flashMode={"on"}
                onCameraReady={onCameraReady}
                useCamera2Api={false}
                ref={(ref) => {
                  setCamera(ref);
                }}
              ></Camera>
            )}
          </View>

          <Animated.Text
            style={{
              opacity: opacityanim,
              position: "absolute",
              marginLeft: screenwidth / 2 - 125,
              zIndex: 100,
              height: 80,
              textAlignVertical: "center",
              top:
                insets.top +
                (screenheight - insets.top - insets.bottom - 103) / 2 -
                40,
              width: 250,
              textAlign: "center",
              alignItems: "center",
              fontFamily: "MontserratSemiBold",
              fontSize: 20,
              color: "#ffffff",
              lineHeight: 29,
            }}
          >
            Point the camera{"\n"}towards your wound
          </Animated.Text>

          {photono < 1 && !photo && (
            <View
              style={{
                backgroundColor: "#000000",
                opacity: 0.35,
                position: "absolute",
                top: 0,
                height:
                  insets.top +
                  (-insets.bottom +
                    screenheight -
                    104 -
                    (324 / 375) * screenwidth) /
                    2,
                width: screenwidth,
                zIndex: 100,
              }}
            />
          )}
          {photono < 1 && !photo && (
            <View
              style={{
                backgroundColor: "#000000",
                opacity: 0.35,
                position: "absolute",
                top:
                  insets.top +
                  (-insets.bottom +
                    screenheight -
                    104 -
                    (324 / 375) * screenwidth) /
                    2,
                height: (324 / 375) * screenwidth,
                zIndex: 100,
                left: 0,
                width: (screenwidth - (311 / 375) * screenwidth) / 2,
              }}
            />
          )}
          {photono < 1 && !photo && (
            <View
              style={{
                backgroundColor: "#000000",
                opacity: 0.35,
                position: "absolute",
                top:
                  insets.top +
                  (-insets.bottom +
                    screenheight -
                    104 -
                    (324 / 375) * screenwidth) /
                    2,
                height: (324 / 375) * screenwidth,
                zIndex: 100,
                right: 0,
                width: (screenwidth - (311 / 375) * screenwidth) / 2,
              }}
            />
          )}
          {photono < 1 && !photo && (
            <View
              style={{
                backgroundColor: "#000000",
                opacity: 0.35,
                position: "absolute",
                top:
                  insets.top +
                  (-insets.bottom +
                    screenheight -
                    104 -
                    (324 / 375) * screenwidth) /
                    2 +
                  (324 / 375) * screenwidth,
                width: screenwidth,
                zIndex: 100,
                height: 500,
              }}
            />
          )}

          {photono < 1 && !photo && (
            <Image
              source={require("../assets/images/cameraoverlayborder.png")}
              style={{
                zIndex: 100,
                width: (311 / 375) * screenwidth,
                height: (324 / 375) * screenwidth,
                position: "absolute",
                left: (screenwidth - (311 / 375) * screenwidth) / 2,
                top:
                  insets.top +
                  (-insets.bottom +
                    screenheight -
                    104 -
                    (324 / 375) * screenwidth) /
                    2,
              }}
            />
          )}

          {photono > 0 && recording2 && (
            <View
              style={[
                {
                  width: screenwidth,
                  top: 0,
                  backgroundColor: "#1F2E99",
                  height: 131 + insets.top,
                  position: "absolute",
                },
              ]}
            />
          )}

          {recording2 && (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              {counter > -1 && (
                <View
                  style={{
                    backgroundColor: "#FE7235",
                    width: 13,
                    height: 13,
                    borderRadius: 6.5,
                    marginTop: 61 + insets.top,
                  }}
                />
              )}

              {counter > -1 && (
                <Text
                  style={[styles.title1, { marginTop: 53 + 6 + insets.top }]}
                >
                  00:{counter < 10 && "0"}
                  {counter}
                </Text>
              )}

              {counter < -1 && (
                <Text style={[styles.title2, { marginTop: 17 + insets.top }]}>
                  {Math.abs(counter + 1)}
                </Text>
              )}
              {counter == -1 && (
                <Text style={[styles.title3, { marginTop: 53 + insets.top }]}>
                  Recording starts...
                </Text>
              )}
            </View>
          )}

          {!recording2 && !photo && !video && (
            <View
              style={{
                backgroundColor: "#FCF5EF",
                width: screenwidth,
                height: 104 + insets.bottom,
                flex: 1,
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                zIndex: 200,
              }}
            >
              <TouchableOpacity
                disabled={!isCameraReady}
                onPress={() => {
                  if (photono < 1) {
                    takePicture();
                  } else {
                    setRecording2(true);
                    takeVideorecording();
                  }
                }}
              >
                {photono < 1 && (
                  <Image
                    source={require("../assets/images/camerashootbuttonnew.png")}
                    style={{
                      width: 72,
                      height: 72,
                      marginTop: 13,
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>

              {photono < 1 && (
                <TouchableOpacity
                  style={{ position: "absolute", top: 34 - 13, left: "6%" }}
                  onPress={async () => {
                    await pickFromGallery();
                  }}
                >
                  <Image
                    style={{
                      width: 97,
                      height: 51,
                      marginTop: 13,
                      alignSelf: "center",
                    }}
                    source={require("../assets/images/imagegallerybutton.png")}
                  />
                </TouchableOpacity>
              )}

              <TouchableOpacity
                style={{ position: "absolute", top: 32 - 13, right: "6%" }}
                onPress={() => {
                  switchCamera();
                }}
              >
                <Image
                  style={{
                    width: 108,
                    height: 53,
                    marginTop: 13,
                    alignSelf: "center",
                  }}
                  source={require("../assets/images/switchcamerabutton.png")}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {!completed && photo && photono < -2 && (
        <OrangeButton
          onPress={() => {
            nextPhoto();
          }}
          nextarrow
          title="Next photo"
          width={196}
        />
      )}

      {!completed && ((photo && photono == 0) || video) && (
        <View
          style={{
            position: "absolute",
            backgroundColor: "#FCF5EF",
            width: screenwidth,
            height: 106 + insets.bottom,
            bottom: 0,
            borderRadius: 5,
            position: "absolute",
            zIndex: 10,
          }}
        />
      )}

      {!completed && photo && photono == 0 && !video && (
        <TouchableOpacity
          style={{
            position: "absolute",
            bottom: 24 + insets.bottom,
            left: "6%",
            zIndex: 100,
          }}
          onPress={async () => {
            await retakePicture();
          }}
        >
          <View>
            <TopInset />
            <Image
              source={require("../assets/images/cameraretakebutton.png")}
              style={{ width: 156, height: 65 }}
            />
          </View>
        </TouchableOpacity>
      )}
      {!completed && photo && photono == 0 && (
        <TouchableOpacity
          style={{
            position: "absolute",
            bottom: 24 + insets.bottom,
            right: "6%",
            zIndex: 100,
          }}
          onPress={() => {
            props.nextStep(photo);
          }}
        >
          <View style={styles.buttonshadowexit}>
            <Image
              source={require("../assets/images/camerayesbutton.png")}
              style={{ width: 156, height: 65 }}
            />
          </View>
        </TouchableOpacity>
      )}

      {video && !completed && (
        <TouchableOpacity
          style={{
            position: "absolute",
            bottom: 24 + insets.bottom,
            left: "6%",
            zIndex: 100,
          }}
          onPress={async () => {
            await retakePicture();
          }}
        >
          <View style={styles.buttonshadowretake}>
            <TopInset />
            <Image
              source={require("../assets/images/cameraretakebutton.png")}
              style={{ width: 156, height: 65 }}
            />
          </View>
        </TouchableOpacity>
      )}

      {photo && photono == 0 && !video && (
        <View
          style={{
            backgroundColor: "#000000",
            opacity: 0.35,
            width: screenwidth,
            height: 193 + insets.top,
            zIndex: 10,
            top: 0,
            position: "absolute",
          }}
        />
      )}

      {photono > 0 && !recording2 && !completed && (
        <View
          style={[
            {
              width: screenwidth,
              top: 0,
              height: 193 + insets.top,
              position: "absolute",
              backgroundColor: "#000000",
              opacity: 0.35,
            },
            recording2 && { height: 131 + insets.top },
          ]}
        />
      )}

      {!completed && video && (
        <View
          style={{
            backgroundColor: "#000000",
            opacity: 0.35,
            width: screenwidth,
            height: 193 + insets.top,
            zIndex: 10,
            top: 0,
            position: "absolute",
          }}
        />
      )}

      {completed && !isLoading && false && (
        <OrangeButton
          disabled={isLoading}
          onPress={sendVideo}
          sendicon
          title="Send all photos & the  video"
          width={"84%"}
        />
      )}
      {completed && isLoading && false && (
        <OrangeButton
          disabled={true}
          sendicon
          title="Sending..."
          width={"84%"}
        />
      )}

      {completed && (
        <View
          style={{
            position: "absolute",
            height: 168,
            top: ((isLoading ? 0 : 1) * (screenheight - screenwidth)) / 2 + 50,
            alignItems: "center",
            width: screenwidth,
          }}
        ></View>
      )}

      {isLoading && (
        <Text
          style={{
            fontFamily: "Questrial",
            fontSize: 64,
            color: "#1F2E99",
            width: "100%",
            top: screenwidth / 2 + 100,
            textAlign: "center",
            zIndex: 100,
            position: "absolute",
          }}
        >
          {uploadPercent}%
        </Text>
      )}
      {(isLoading || isLoadingFinished) && (
        <Text
          style={{
            fontFamily: "Questrial",
            fontSize: 20,
            color: "#0077FF",
            width: "100%",
            bottom: insets.bottom + 100,
            textAlign: "center",
            zIndex: 100,
            position: "absolute",
          }}
        >
          {ulmessage}
        </Text>
      )}

      {completed && !isLoading && !uploadcompleted && (
        <OrangeButton
          width={"84%"}
          onPress={() => {
            setCompleted(true);
            setIsLoading(true);
            sendVideo();
          }}
          title="Retry"
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  buttonshadowexit: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,

    backgroundColor: "transparent",
  },
  buttonshadowretake: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
    backgroundColor: "transparent",
  },
  cameraexitbutton: {
    width: 82,
    height: 37,
  },
  cancelupload: {
    width: 179,
    height: 37,
  },
  tutorialbutton: {
    width: 121,
    height: 39,
  },
  cameraretakebutton: {
    width: 117,
    height: 37,
  },
  titlecamera1: {
    backgroundColor: "transparent",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#1F2E99",
    marginTop: 25,
  },
  titlecamera2: {
    position: "relative",
    fontSize: 20,
    fontFamily: "LatoBold",
    color: "#1F2E99",
  },
  scrollviewbottom: {
    height: 180,
    flex: 1,
    backgroundColor: "transparent",
  },
  scrollviewcontainter: {
    marginTop: 250,
    backgroundColor: "transparent",
  },

  menuicon: {
    width: 99,
    height: 37,
    position: "absolute",
    top: 38,
    left: "7.8%",
  },
  imageday: {
    width: 93,
    height: 37,
    position: "absolute",
    top: 38,
    right: "3.8%",
  },
  titleday: {
    fontSize: 20,
    color: "#FE7235",
    fontFamily: "Questrial",
    position: "absolute",
    top: 45,
    right: "10%",
  },
  image: {
    flex: 1,
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  container: {
    backgroundColor: "#FCF5EF",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    color: "#1F2E99",
    fontFamily: "Montserrat",
  },

  title1: {
    alignSelf: "center",

    marginLeft: 8,
    fontFamily: "MontserratSemiBold",
    fontSize: 24,
    color: "#ffffff",
  },
  title2: {
    alignSelf: "center",

    lineHeight: 103,
    textAlignVertical: "center",
    fontFamily: "Questrial",
    fontSize: 100,
    color: "#ffffff",
  },
  title3: {
    alignSelf: "center",
    marginTop: 53 + 20,
    fontFamily: "MontserratSemiBold",
    fontSize: 24,
    color: "#ffffff",
  },

  separator: {
    marginVertical: 30,
    height: 1,

    width: "80%",
  },
});
