import * as React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { OrangeButton } from "./OrangeButton";

export const ActivityQAWizard = (props: any): JSX.Element => {
  return (
    <View style={styles.container}>
      <View
        style={{
          height: "100%",
          maxWidth: 500,
          alignSelf: "center",
        }}
      >
        <Text style={styles.title1}>
          Why answer{"\n"}
          these questions?
        </Text>
        <Image
          source={require("../assets/images/qawizardimg.png")}
          style={styles.qawizardimg}
        />

        <Text style={styles.title2}>
          Your answers tell us how well you are recovering after your surgery.
          We will share this data with your clinical team to keep them updated
          of your recovery.{" "}
        </Text>

        <Text style={styles.title3}>
          ©Oxford University Innovation Limited, 1998. All rights reserved.
          Oxford Hip Score – English for the United Kingdom{" "}
        </Text>

        <OrangeButton
          onPress={() => {
            props.nextStep();
          }}
          startqa
          title="Start questionnaire"
          width={"84%"}
          style={{ marginTop: 20 }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  qawizardimg: {
    width: 157,
    height: 144,
    marginTop: "10%",
    alignSelf: "center",
  },

  container: {
    flex: 1,
    backgroundColor: "#E5E5F4",

    paddingHorizontal: "8%",
  },
  title1: {
    width: "83%",
    fontSize: 28,
    fontFamily: "MontserratSemiBold",
    color: "#1F2E99",

    marginTop: "10%",
    left: "8%",
  },
  title2: {
    width: "82%",
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
    marginTop: "15%",
    left: "8%",
  },
  title3: {
    width: "82%",
    fontSize: 10,
    fontFamily: "Lato",
    color: "#2A78F1",
    marginTop: 27,
    left: "8%",
  },
});
