import * as React from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

export const OrangeButton = (props: any): JSX.Element => (
  <TouchableOpacity
    disabled={props.disabled}
    style={[styles.listbutton, props.style, { width: props.width }]}
    onPress={props.onPress}
  >
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        backgroundColor: "transparent",
      }}
    >
      {Boolean(props?.startqa) && (
        <Image
          source={require("../assets/images/startqaicon.png")}
          style={styles.startqa}
        />
      )}

      <Text
        style={[
          {
            color: "#FFFFFF",
            fontFamily: "Questrial",
            fontSize: 20,
            lineHeight:
              20 + (props?.sendicon ? 2.5 : 0) + (props?.camera ? 0 : 0),
          },
          props.blueborder && { color: "#1F2E99" },
        ]}
      >
        {props.title}
      </Text>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  listbutton: {
    paddingTop: 20,
    alignItems: "center",
    height: 65,
    width: 311,
    borderRadius: 15,
    backgroundColor: "#FE5935",
    alignSelf: "center",
    alignContent: "center",
  },
  videoicon: {
    marginTop: 0,
    marginRight: 18,
    width: 34,
    height: 24,
  },
  sendicon: {
    marginLeft: 15,
    width: 16,
    height: 16,
  },
  adduser: {
    marginRight: 8.33,
    width: 16.6,
    height: 13,
  },
  thumbsupicon: {
    marginRight: 20,
    width: 28,
    height: 28,
  },

  startqa: {
    marginRight: 20,
    width: 20,
    height: 20,
  },

  cameraicon: {
    marginRight: 20,
    width: 29,
    height: 24,
  },

  nextarrow: {
    marginTop: 7,
    marginLeft: 20,
    width: 20,
    height: 11,
  },

  buttonshadowexit: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,

    backgroundColor: "transparent",
  },
  buttonshadowretake: {
    shadowColor: "#818181",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
    backgroundColor: "transparent",
  },
  cameraexitbutton: {
    width: 81,
    height: 37,
  },
  cameraretakebutton: {
    width: 117,
    height: 37,
  },
  titlecamera1: {
    backgroundColor: "transparent",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#1F2E99",
    marginTop: 25,
  },
  titlecamera2: {
    position: "relative",
    fontSize: 20,
    fontFamily: "LatoBold",
    color: "#1F2E99",
  },
  scrollviewbottom: {
    height: 180,
    flex: 1,
    backgroundColor: "transparent",
  },
  scrollviewcontainter: {
    marginTop: 250,
    backgroundColor: "transparent",
  },

  scrollView: {},

  card: {
    width: "10%",
    aspectRatio: 311 / 274,
    resizeMode: "contain",
    marginTop: 10,
    marginLeft: 5,
  },
  menuicon: {
    width: 99,
    height: 37,
    position: "absolute",
    top: 38,
    left: "7.8%",
  },
  imageday: {
    width: 93,
    height: 37,
    position: "absolute",
    top: 38,
    right: "3.8%",
  },
  titleday: {
    fontSize: 20,
    color: "#FE7235",
    fontFamily: "Questrial",
    position: "absolute",
    top: 45,
    right: "10%",
  },
  image: {
    flex: 1,
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  container: {
    backgroundColor: "#FCF5EF",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    color: "#1F2E99",
    fontFamily: "Montserrat",
  },

  separator: {
    marginVertical: 30,
    height: 1,

    width: "80%",
  },
});
