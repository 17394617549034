import React from "react";
import { Alert, View } from "react-native";

import { useFonts } from "expo-font";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { VerifyBirthyear } from "./components/VerifyBirthyear";
import { ConsentApproval } from "./components/ConsentApproval";
import { ActivityCameraWizard } from "./components/ActivityCameraWizard";
import { ActivityCamera } from "./components/ActivityCamera";
import { ActivityQAWizard } from "./components/ActivityQAWizard";
import { ActivityQA } from "./components/ActivityQA";
import { LinkformDone } from "./components/LinkformDone";
import { LinkformUploadDone } from "./components/LinkformUploadDone";
import Constants from "expo-constants";
import * as Sentry from "sentry-expo";
import { ActivityVideo } from "./components/ActivityVideo";

global.environment = Constants.manifest?.extra?.environment ?? "LOCAL";

if (Constants.manifest?.extra?.sentryDSN !== undefined) {
  Sentry.init({
    environment: global.environment,
    dsn: Constants.manifest.extra.sentryDSN,
    enableInExpoDevelopment: true,
  });
}

export default function App(): JSX.Element {
  const [formstate, setFormstate] = React.useState(1);
  const [photo, setPhoto] = React.useState();
  const [video, setVideo] = React.useState<{
    asset_id: any;
    public_id: any;
    secure_url: any;
  }>();
  const [linkyear, setLinkyear] = React.useState();
  const [isLoading] = React.useState(false);

  useFonts({
    Montserrat: require("./assets/fonts/Montserrat-Regular.ttf"),
    MontserratMedium: require("./assets/fonts/Montserrat-Medium.ttf"),
    MontserratSemiBold: require("./assets/fonts/Montserrat-SemiBold.ttf"),
    Lato: require("./assets/fonts/Lato-Regular.ttf"),
    LatoBold: require("./assets/fonts/Lato-Bold.ttf"),
    Questrial: require("./assets/fonts/Questrial-Regular.ttf"),
  });

  const urlarray = window.location.pathname.split("/");
  const linkcode = urlarray[urlarray.length - 1];

  if (!linkcode && global.environment !== "LOCAL") {
    location.href = "https://postop.ai/";
  }

  global.apiurl = Constants.manifest?.extra?.apiUrl;

  function setFormstateIfActivityExists(nextvalue: number): void {
    switch (nextvalue) {
      case 3: // if there is a wound activity open the wound photo component otherwise move to next
        if (global.patientactivityextra.photo == 1) {
          setFormstate(3);
        } else {
          setFormstateIfActivityExists(9);
        }
        break;
      case 9:
        if (global.patientactivityextra.video == 1) {
          setFormstate(9);
        } else {
          setFormstateIfActivityExists(5);
        }
        break;

      case 5: // if there is a QA activity open the wound QA component otherwise move to next
        if (global.patientactivityextra_qa) {
          setFormstate(5);
        } else {
          setFormstate(7);
        }
        break;
    }
  }

  function savelinkform(): void {
    fetch(`${global.apiurl}/linkform/savelinkform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        linkcode,
        linkyear,
        ...(global.patientactivityextra.photo && {
          photobase64: photo.base64,
        }),
        ...(global.patientactivityextra_qa && { answers: global.answers }),
        ...(video && {
          video: {
            video_asset_id: video.asset_id,
            video_public_id: video.public_id,
            video_url: video.secure_url,
          },
        }),
      }),
    })
      .then(async (response) => await response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.result) {
          setFormstate(8);
        } else {
          alert("Upload error\nPlease try again");
        }
      })
      .catch((err) => {
        console.log(err);
        Alert.alert("Upload error", "Please try again");
      });
  }

  return (
    <SafeAreaProvider>
      <View style={{ flex: 1 }}>
        {formstate === 1 && (
          <VerifyBirthyear
            disabled={false}
            linkcode={linkcode}
            setLinkyear={setLinkyear}
            nextStep={(value: any) => {
              setFormstate(2);
            }}
          />
        )}
        {formstate === 2 && (
          <ConsentApproval
            disabled={false}
            linkcode={linkcode}
            nextStep={() => {
              setFormstateIfActivityExists(3);
            }}
          />
        )}
        {formstate === 3 && (
          <ActivityCameraWizard
            nextStep={() => {
              setFormstate(4);
            }}
          />
        )}
        {formstate === 4 && (
          <ActivityCamera
            nextStep={(photo: React.SetStateAction<undefined>) => {
              setPhoto(photo);
              setFormstateIfActivityExists(9);
            }}
          />
        )}
        {formstate === 9 && (
          <ActivityCameraWizard
            nextStep={() => {
              setFormstate(10);
            }}
            mediaType="video"
          />
        )}
        {formstate === 10 && (
          <ActivityVideo
            nextStep={(Video: React.SetStateAction<undefined>) => {
              setVideo(Video);
              setFormstateIfActivityExists(5);
            }}
          />
        )}
        {formstate === 5 && (
          <ActivityQAWizard
            nextStep={() => {
              setFormstate(6);
            }}
          />
        )}
        {formstate === 6 && (
          <ActivityQA
            linkyear={linkyear}
            linkcode={linkcode}
            nextStep={() => {
              setFormstate(7);
            }}
          />
        )}
        {formstate === 7 && (
          <LinkformDone
            title={isLoading ? "Uploading..." : "Send tasks"}
            loading={isLoading}
            sendLinkform={() => {
              savelinkform();
            }}
          />
        )}
        {formstate === 8 && (
          <LinkformUploadDone title={"Send tasks"} loading={false} />
        )}
      </View>
    </SafeAreaProvider>
  );
}
