import * as React from "react";
import { StyleSheet, Text, View, Image } from "react-native";

export const LinkformUploadDone = (props: any, route: any): JSX.Element => (
  <View style={{ maxWidth: 500, alignSelf: "center" }}>
    <Image
      source={require("../assets/images/logopostop1.1.png")}
      style={styles.logo}
    />

    <Text style={styles.title1}>Upload was{"\n"}successfull! </Text>

    <Text style={styles.title2}>
      We received all the information you submitted.{"\n"}
      Thank you for your time!
    </Text>

    <Image
      source={require("../assets/images/uploaddonesmiley.png")}
      style={styles.uploaddonesmiley}
    />
  </View>
);

const styles = StyleSheet.create({
  logo: {
    width: 210.5,
    height: 69,
    marginLeft: "8%",
    marginTop: 65,
  },
  uploaddonesmiley: {
    width: 84,
    height: 87,
    marginTop: 130,
    alignSelf: "center",
  },
  countryflag: {
    width: 26,
    height: 18,
    marginHorizontal: 16,
    marginVertical: 19,
  },
  phoneinput: {
    flex: 1,
    color: "#1F2E99",
    fontFamily: "Montserrat",
    fontSize: 20,
    marginRight: 10,
    marginLeft: 5,
  },

  container: {
    flex: 1,
    alignContent: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#ffffff",
  },
  title1: {
    width: "83%",
    fontSize: 24,
    fontFamily: "MontserratSemiBold",
    color: "#1F2E99",
    marginTop: 30,
    marginLeft: "8%",
  },
  title2: {
    width: "83%",
    fontSize: 18,
    fontFamily: "Lato",
    color: "#1F2E99",
    marginTop: 23,
    marginLeft: "8%",
  },
  title3: {
    width: "83%",
    fontSize: 20,
    fontFamily: "Questrial",
    color: "#798599",
    marginTop: 34,
    marginLeft: "8%",
  },
  title3a: {
    fontSize: 15,
    fontFamily: "Questrial",
    color: "#4F4F4F",
    marginLeft: "8%",
  },
  title4: {
    fontSize: 15,
    fontFamily: "Lato",
    textDecorationLine: "underline",
    color: "#0077FF",

    position: "relative",
  },
  title5: {
    width: "83%",
    fontSize: 15,
    fontFamily: "Lato",
    color: "#0077FF",
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
