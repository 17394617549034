export interface SimpleResult {
  message: string;
  result: boolean;
}
export type Result<T> = SimpleResult & T;

export interface Question {
  question: string;
  questionairetype_id: number;
  a1: string;
  a2: string;
  a3: string;
  a4: string;
  a5: string;
  id: number;
  isslider: boolean;
  showonlyquestion: boolean;
  showonlyanswer: boolean;
  dontshowforquestion: number | null;
  dontshowforanswer: number | null;
}

export type QuestionList = Question[];

export async function fetchQuestions(
  linkcode: string,
  linkyear: string
): Promise<Result<{ questions: QuestionList }>> {
  const response = await fetch(`${global.apiurl}/linkform/getlinkquestions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      linkcode,
      linkyear,
    }),
  });

  return await response.json();
}
