import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { OrangeButton } from "../components/OrangeButton";

export const ActivityCameraWizard = (props: any): JSX.Element => {
  const mediaName = props.mediaType === "video" ? "video" : "photo";
  return (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
          alignItems: "flex-start",
          height: "100%",
          marginTop: 31,
          maxWidth: 500,
          alignSelf: "center",
        }}
      >
        <Text style={styles.title1}>
          Why take a {mediaName}
          {"\n"}of your wound?{" "}
        </Text>
        <Text style={styles.title2}>
          Keeping track of its progress allows us
          {"\n"}to detect infection.{" "}
        </Text>

        <Image
          source={require("../assets/images/camerawizard.png")}
          style={styles.camerawizard}
        />

        <Text style={styles.title2}>
          Please make sure to take the {mediaName} in a well lit area with your
          wound visible.
        </Text>

        <OrangeButton
          onPress={() => {
            props.nextStep();
          }}
          title={`Take a ${mediaName}`}
          width={"84%"}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  camerawizard: {
    width: 302,
    height: 229,
    alignSelf: "center",
    marginVertical: 33,
  },
  bgimage: {
    flex: 1,
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  backbutton: {
    width: 208,
    height: 37,
  },
  bgimageex: {
    flex: 1,
    resizeMode: "cover",
    width: "57%",
    height: "57%",
  },

  tempicon: {
    flex: 1,
    resizeMode: "cover",
    width: "37.6%",
    height: "37.6%",
  },

  pager: {
    flex: 1,

    height: 450,
  },

  container: {
    flex: 1,
    backgroundColor: "#1F2E99",
    width: "100%",
  },
  termsbg: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    width: "100%",
  },
  title1: {
    alignSelf: "flex-start",
    fontSize: 28,
    fontFamily: "MontserratSemiBold",
    color: "#FFFFFF",
    marginLeft: "8%",
    width: "82%",
    marginBottom: 16,
  },

  title2: {
    marginLeft: "8%",
    width: "82%",
    fontSize: 18,
    lineHeight: 32,
    fontFamily: "Lato",
    color: "#FCF5EF",
    marginBottom: 36,
  },

  title3: {
    width: "83%",
    fontSize: 20,
    fontFamily: "Lato",
    color: "#FFFFFF",
    paddingRight: "8%",
  },

  title4: {
    fontSize: 20,
    fontFamily: "LatoBold",
    color: "#FFFFFF",
  },
  title5: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#FFFFFF",

    marginBottom: 60,
    lineHeight: 20,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
